import React, { useState, useEffect } from 'react';
import { db } from '../firebase.js'
import { getFirestore, addDoc, collection , doc} from 'firebase/firestore';
import { format } from 'moment';
const moment = require('moment'); // Import Moment.js

function formatDate(dateString) {
  const date = moment(dateString, "YYYYMMDD,HH:mm:ss+0000"); // Specify format
  return date.format('MMM DD, YYYY h:mm A'); 
}

export default function MessageModal(props) {
  const data = props.isModalOpen  
  const onClose = props.onClose
  const product = props.product 
  const customer = props.customer 
  const [isSuccessToastVisible, setIsSuccessToastVisible] = useState(false);
  const [isErrorToastVisible, setIsErrorToastVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(data);
  const [name, setName] = useState('');
  const [contactInfo, setContactInfo] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleClose = () => {
    setIsModalOpen(false);
    onClose();
  };

  const areFieldsInvalid = () => {
    var error = false;
    if(name.length == 0) {
      setErrorMessage('Please enter a name');
      error = true 
    } else if (!contactInfo) {
      setErrorMessage('Please enter valid contact information');
      error = true 
    } else if (!message) {
      setErrorMessage('Please enter your message to the seller');
      error = true 
    }
    return error;
  }

  const handleNameEdit = (e) => {
    setName(e.target.value);
    setErrorMessage('');
  }

  const handleContactEdit = (e) => {
    setContactInfo(e.target.value);
    setErrorMessage('');
  }

  const handleMessageEdit = (e) => {
    setMessage(e.target.value);
    setErrorMessage('');
  }

  const delay = ms => new Promise(res => setTimeout(res, ms));
  
  const handleSubmit = async () => {
    const sender = {
      id: 'anonymous',
      displayName: name,
      contactInfo: contactInfo
    }
    const dataToWrite = {
        sender: sender,
        message: message,
        productId: product,
        sentDate: moment().utc().format("YYYYMMDD,HH:mm:ss+0000"),
    };

    if (areFieldsInvalid()) {
      setIsErrorToastVisible(true);
      return 
    }
    try {
      const custColRef = collection(db, "customers")
      const custDocRef = doc(custColRef, customer)
      const mesColRef = collection(custDocRef, "messages")
      const docRef = await addDoc(mesColRef, dataToWrite);

      // console.log('Document written with ID:', docRef.id);
      setIsSuccessToastVisible(true);
      setIsErrorToastVisible(false);
      await delay(1500);
      handleClose();
    } catch (error) {
      console.error('Error writing document:', error);
      setIsErrorToastVisible(true);
      setErrorMessage(error)
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsSuccessToastVisible(false);
    }, 3000); // Hide toasts after 3 seconds

    return () => clearTimeout(timeout);
  }, [isSuccessToastVisible]);

  return (
    <>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <form>
              {/* Flexbox for inline labels and inputs */}
              <div className="form-group">
                <div className="form-row">
                  <label htmlFor="name">Name:</label>
                  <input type="text" id="name" value={name} placeholder="Name" className="form-input" onChange={handleNameEdit}/>
                </div>
              </div>
              <div className="form-group">
                <div className="form-row">
                  <label htmlFor="contactInfo">Contact Information:</label>
                  <input type="text" id="contactInfo" value={contactInfo} placeholder="Phone number or email" className="form-input" onChange={handleContactEdit} />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="message">Message:</label>
                <textarea
                  id="message"
                  value={message}
                  onChange={handleMessageEdit}
                />
              </div>
              <button type="button" className="w-commerce-commercebuynowbutton button-primary buy-now" onClick={handleSubmit}>Submit</button> &nbsp;
              <button type="button" className="w-commerce-commercebuynowbutton button-secondary buy-now" style={{ width: 'auto' }} onClick={handleClose}>Cancel</button>
            </form>
          </div>
        </div>
      )}

    {isSuccessToastVisible && (
      <div className="toast success-toast">Message sent successfully!</div>
    )}
    {isErrorToastVisible && (
      <div className="toast error-toast">{ errorMessage }</div>
    )}
    </>
  );
}
