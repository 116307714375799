import { db } from '../firebase.js'
import { query, collectionGroup, orderBy, getDocs, limit } from "firebase/firestore";
import $ from 'jquery'; 
import { useEffect, useState} from 'react';
import { Component } from 'react';
import React from 'react';
import NotFound from './notFound';
import AdaptiveImage from './adaptiveImage.js'

export default function ProductList(props) {
  const data = props.data  
  const searchQuery = props.searchQuery

  return (
    <div className="ProductList">
    <>
      { data.length > 0 ? (
      
      <div className="w-dyn-list">
        <div role="list" className="latest-products-section-grid w-dyn-items">
        { data.map((c, index) => (
            <div
              role="listitem"
              className="latest-product-section w-dyn-item"
              key={`${c.created}-${index}`}
            >
            <a href={c.productUri} className="latest-product-wrapper w-inline-block">
            <div className="image-wrapper latest-product">

            <AdaptiveImage src={c.mainImage}/>
              
            </div>
            <div className="latest-product-about-wrapper">
              <div className="split-content latest-product-about-left">
                <h3 className="title latest-product">{c.title}</h3>
                <div className="latest-product-author-wrapper">
                  {/*<img src="images/empty-profile.jpg" alt="" className="image latest-product-author" />
                  unknown*/}
                <div />
              </div>
            </div>
            <div className="badge latest-product">
              <div>{c.price}</div>
                <div />
              </div>
            </div>
            </a>
          </div>
        ))}
        </div>
      </div>
       ) : (
      <>
      <div className="empty-state w-dyn-empty">
        <div>No items found { searchQuery ? (
                <>
                containing '{searchQuery}'
                </>
                ) : (<>.</>) }
                </div>
      </div>        
      </>)}
      </>
    </div>
  );
}
