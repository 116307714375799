
export default function Footer() {
  return (
    <div className="Footer">
      <footer className="footer">
  <div className="container-default">
    <div className="footer-menu-wrapper">
      <div
        data-w-id="34a9bbb3-6b01-f548-fa5c-221f57b9fdb3"
        className="footer-main-content-wrapper"
      >
        <a
          href="/"
          aria-current="page"
          className="footer-logo-container w-inline-block w--current"
        >
          <img
            src="images/Shopik-Black-Logo-2400x1800.png"
            loading="lazy"
            sizes="(max-width: 240px) 87vw, 150px"
            srcSet="images/Shopik-Black-Logo-2400x1800-p-500.png 500w, images/Shopik-Black-Logo-2400x1800-p-800.png 800w, images/Shopik-Black-Logo-2400x1800.png 2400w"
            alt=""
            style={{ maxWidth: 200 }}
          />
        </a>
      </div>
      <div className="footer-menu-links-wrapper">
        <div
          data-w-id="2ca061b9-ef51-6eb4-ac91-9bbf1a05d912"
          className="footer-links-wrapper"
        >
          <div className="title footer-title-links">Menu</div>
          <div className="divider footer-links" />
          <div className="footer-content-links">
            <ul role="list" className="list-footer w-list-unstyled">
              <li className="footer-list-item">
                <a
                  href="/"
                  aria-current="page"
                  className="footer-link w--current"
                >
                  Home
                </a>
              </li>
              <li className="footer-list-item">
                <a href="https://shopik.io/" className="footer-link">
                  About
                </a>
              </li>
              <li className="footer-list-item">
                <a href="mailto:hello@shopik.io" className="footer-link">
                  Contact
                </a>
              </li>
              <li className="footer-list-item">
                <a href="/products" className="footer-link">
                  Latest Products
                </a>
              </li>
            </ul>
            <div className="space footer-links" />
            <ul role="list" className="list-footer w-list-unstyled" />
          </div>
        </div>
      </div>
      <div
        data-w-id="883a15b3-f2dd-5615-b19d-ddcfaae0beab"
        className="footer-join-wrapper" hidden
      >
        <div className="footer-join">
          <div className="image-wrapper icon-join-footer">
            <img
              src="images/Shopik-Black-Logo-2400x1800.png"
              loading="lazy"
              sizes="53.99305725097656px"
              srcSet="images/Shopik-Black-Logo-2400x1800-p-500.png 500w, images/Shopik-Black-Logo-2400x1800-p-800.png 800w, images/Shopik-Black-Logo-2400x1800.png 2400w"
              alt=""
            />
          </div>
          <h3>Become a Seller</h3>
          <p className="paragraph join-footer">The Seller's&nbsp;Marketplace</p>
          <a
            href="contact.html"
            className="button-primary small full-width w-button"
          >
            Sell Now
          </a>
        </div>
      </div>
    </div>
  </div>
</footer>

    </div>
  );
}
