import {useEffect, useLocation} from 'react';
import Product from './components/product';
import TopNav from './components/navBar';
import NotFound from './components/notFound';
import Footer from './components/footer';
import HomeContent from './components/home';
import ProductsHome from './components/productsHome'
import LatestProducts from './components/latestProducts'
import EBayOAuth from './components/ebayLogin'
import FrameTest from './components/frameTest';
import './firebase'  
import './shopik.marketplace.default.css'

import { Helmet, HelmetProvider } from 'react-helmet-async';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: (<><TopNav /><ProductsHome /><Footer /></>),
    errorElement: (
      <><TopNav /><NotFound /><Footer /></>
    ),
  },
  {
    path: "marketplace",
    element: (<><TopNav /><ProductsHome /><Footer /></>),
    errorElement: (
      <><TopNav /><NotFound /><Footer /></>
    ),
  },
  {
    path: "m",
    element: (<><TopNav /><ProductsHome /><Footer /></>),
    errorElement: (
      <><TopNav /><NotFound /><Footer /></>
    ),
  },
  {
    path: "products/:customer/:product",
    element: (<><TopNav /><Product /><Footer /></>),
  },
  {
    path: "products/:customer",
    element: (<><TopNav /><ProductsHome /><Footer /></>),
  },
  {
    path: "products/search/:string",
    element: (<><TopNav /><ProductsHome /><Footer /></>),
  },
  {
    path: "products",
    element: (<><TopNav /><ProductsHome /><Footer /></>),
  }, 
  {
    path: "casestudies/:customer",
    element: (<><FrameTest /></>),
  }, 
  {
    path: "ebayLogin/:customer/:destination",
    element: (<><TopNav /><EBayOAuth /><Footer /></>),
  },
  {
    path: "ebayLogin",
    element: (<><TopNav /><EBayOAuth /><Footer /></>),
  }
]);

function App() {
  useEffect(()=>{
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require('ix2').init();
    document.dispatchEvent(new Event('readystatechange'))
  })

  return (
    <HelmetProvider>
    <div className="App">
       <RouterProvider router={router} />
    </div>
    </HelmetProvider>
  );
}

export default App;
