import React, { useState, useEffect } from 'react';
import heic2any from "heic2any";

function AdaptiveImage({ src }) {
    const [imageSrc, setImageSrc] = useState(src);
    const [attemptedConversion, setAttemptedConversion] = useState(false);
  
    useEffect(() => {
      // Set initial image source
      setImageSrc(src);
    }, [src]);
  
    const handleImageError = () => {
      if (!attemptedConversion) {  // Only attempt conversion once
        fetch(src)
          .then(response => response.blob())
          .then(blob => heic2any({
              blob,
              toType: "image/jpeg",
              quality: 0.8
          }))
          .then(conversionResult => {
            setImageSrc(URL.createObjectURL(conversionResult));
            setAttemptedConversion(true); // Update state to prevent looping
          })
          .catch(e => console.error("Error converting image:", e));
      } else {
        // Fallback image or error handling if conversion also fails
        setImageSrc('path_to_fallback_image.jpg');
      }
    };
  
    return <img src={imageSrc} onError={handleImageError} />;
  }
  
  export default AdaptiveImage;
  
