import { db } from '../firebase.js'
import { query, collectionGroup, orderBy, getDocs, limit } from "firebase/firestore";
import $ from 'jquery'; 
import { useEffect, useState} from 'react';
import { Component } from 'react';
import React from 'react';
import NotFound from './notFound';
import ProductList from './productList'

export default function LatestProducts(props) {
  const data = props.data  
  const isWidget = props.isWidget

  return (
    <div className="LatestProducts">
      <div className="section latest-products">
        <div className="container-default w-container">
          <div
          
            className="top-content latest-products-section"
          >
          { isWidget ? (
            <>
            <h2 className="title latest-products-section">Latest Products</h2>
            <a href="/products" className="explore-ecommerce-link">Explore Latest &nbsp;&nbsp;<span className="explore-arrow"></span></a>
            </>) : (
            <>
            <h2 className="title latest-products-section">All Products</h2>
            </>
            )}
          </div>     
          { <ProductList data={data} />}
        </div>
      </div>
    </div>
  );
}
