import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import { db } from '../firebase.js';
import { getFirestore, updateDoc, collection , doc, getDoc} from 'firebase/firestore';

const EBayOAuth = () => {
  const params = useParams();
  const [authCode, setAuthCode] = useState('');

  const encodedCredentials = btoa(process.env.REACT_APP_EBAY_CLIENT_ID + ':' + process.env.REACT_APP_EBAY_CLIENT_SECRET);
  const exchangeCode = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_EBAY_TOKEN_REFRESH, {
        code: authCode,
        grant_type: 'authorization_code',
        redirect_uri: process.env.REACT_APP_EBAY_REDIRECT_URI,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encodedCredentials,
        },
      })
      const data = response.data;
      saveTokens(data);
    } catch (error) {
      console.error('Error exchanging code:', error);
      throw error;
    }
  };
  
  const saveTokens = async (responseData) => {
    const custId = localStorage.getItem('userId') 
    const destId = localStorage.getItem('destId') 
    const accessToken = responseData.access_token
    const refreshToken  = responseData.refresh_token

    const custColRef = collection(db, "customers")
    const custDocRef = doc(custColRef, custId)

    getDoc(custDocRef).then(async (cdSnap) => {
      var data = cdSnap.data()
      var destinations = data.destinations;
      const foundIndex = destinations.findIndex((item) => item.id === destId);
      
      if (foundIndex !== -1) {
        destinations[foundIndex].accessToken = accessToken;
        destinations[foundIndex].storeKey = refreshToken;
        data.destinations = destinations;
        try {
          await updateDoc(custDocRef,  data).then(() => {
            window.location.href = "shopik://accountsettings/success"  
          });
        } catch (error) {
          console.error('Error merging array:', error);
        }
      } 
    ;})
  }

  useEffect(() => {
    // Function to extract the authorization code from the URL
    const getAuthorizationCode = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      if (code == null) {
        handleLogin()
      } else {
        setAuthCode(code);
      }
    };

    // Check if the authorization code is present in the URL
    if (!authCode) {
       getAuthorizationCode();
    } else if (params['customer'] == null) {
       exchangeCode()
    }
  }, [authCode]);


  // Function to initiate the eBay OAuth login process
  const handleLogin = () => {
    localStorage.setItem('userId', params['customer']);
    localStorage.setItem('destId', params['destination']);
    window.location.href = process.env.REACT_APP_EBAY_AUTH_URL + '?client_id=' + process.env.REACT_APP_EBAY_CLIENT_ID + '&response_type=code&redirect_uri=' + process.env.REACT_APP_EBAY_REDIRECT_URI + '&scope=https://api.ebay.com/oauth/api_scope https://api.ebay.com/oauth/api_scope/sell.marketing.readonly https://api.ebay.com/oauth/api_scope/sell.marketing https://api.ebay.com/oauth/api_scope/sell.inventory.readonly https://api.ebay.com/oauth/api_scope/sell.inventory https://api.ebay.com/oauth/api_scope/sell.account.readonly https://api.ebay.com/oauth/api_scope/sell.account https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly https://api.ebay.com/oauth/api_scope/sell.fulfillment https://api.ebay.com/oauth/api_scope/sell.analytics.readonly https://api.ebay.com/oauth/api_scope/sell.finances https://api.ebay.com/oauth/api_scope/sell.payment.dispute https://api.ebay.com/oauth/api_scope/sell.reputation https://api.ebay.com/oauth/api_scope/sell.reputation.readonly https://api.ebay.com/oauth/api_scope/commerce.notification.subscription https://api.ebay.com/oauth/api_scope/commerce.notification.subscription.readonly https://api.ebay.com/oauth/api_scope/sell.stores https://api.ebay.com/oauth/api_scope/sell.stores.readonly'
  };

  return (
    <div>
      <h1>eBay OAuth connection</h1>
      {authCode ? (
        <p>Authorization Code Obtained, redirecting to app. If you don't automatically get redirected <a href="shopik://accountsettings/success">tap here</a>. </p>
      ) : (
        <></>
      )}
    </div>
  );
};

export default EBayOAuth;
