import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

const FrameTest = () => {
  const params = useParams();
  const iframeRef = useRef(null);
  const [frameUrl, setFrameUrl] = useState(null)
  useEffect(() => {
    const iframe = document.getElementById('uploaderIframe');

    window.addEventListener('message', (event) => {
        // Check the origin of the message
        // if (event.origin !== 'https://shopik-stage.web.app') return;
    
        // Validate the structure of the data
        const { user } = event.data;
        if (user && user.displayName && user.email && user.uid) {
          // console.log('User authenticated:', user);
          // Handle authenticated user data as needed
        }
      });
    //   window.addEventListener('message', (event) => {
    //     // Ensure the message is from the expected origin
    //     // if (event.origin !== 'https://your-react-app-url.com') return;
    
    //     const { frameHeight } = event.data;
    //     if (frameHeight) {
    //       iframe.style.height = frameHeight + 'px';
    //     }
    //   });
    const customer = params.customer
    if (customer ==="taylor") {
      setFrameUrl("https://taylor-auction.s3.us-east-2.amazonaws.com/auction_lots.html")
    } else if (customer === "localhost") {
      setFrameUrl("http://127.0.0.1:3000/bulkuploader/localDebugDeletedRBzAhjfQqPdmVba9D9eIRPAybOd")
    }

    
  }, []);

  return (
      <iframe id="uploaderIframe" style={{
        width: '100%',
        height: '100vh',
        border: 'none',
      }} 
      ref={iframeRef}
      src={frameUrl}></iframe>
  );
};

export default FrameTest;